<template>
  <nav
    class="right_bg close-right-sidebar-16rem z-40 md:right-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative z-10"
   
    v-bind:style="{ right: toggle_right_Sidebar + 'rem' }"
  >
    <div class="">
      <div class="flex bg-emerald-500 justify-between px-2 py-0 mneminics_mt">
        <div class="righ_side_head_">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="4"
            viewBox="0 0 12 4"
            fill="none"
          >
            <path
              d="M11.3333 0H0.666626V1.33333H11.3333V0ZM0.666626 4H11.3333V2.66667H0.666626V4Z"
              fill="#777777"
            ></path>
          </svg>
          <p class="sidebar-heading">DDI</p>
        </div>
        <div>
          <svg
            v-on:click="closeNavigation"
            class="cursor-pointer ml-auto mt-0 w-2 left_close_btn"
            style="text-align: right"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
          >
            <path
              id="close_2_"
              data-name="close (2)"
              d="M8.874,7.636,14.715,1.8A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.8L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0"
              transform="translate(0 -0.136)"
              fill="#b4b5ba"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_two"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
       
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/note.png" alt="" />
            Appearance
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureTwo"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureTwo ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-5">
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Label Color</p>
            <input
              type="color"
              min="0"
              max="13"
              id="color1"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @change="changeGraphLabelColorHandler($event)"
              :value="graphLabelColor"
            />
          </div>

          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Graph Color</p>
            <input
              type="color"
              min="0"
              max="13"
              id="color1"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @change="changeGraphColorHandler($event)"
              :value="graphColor"
            />
          </div>
          <div class="flex flex-col mt-3">
            <p class="label_heading">X-axis label size</p>
            <input
              type="number"
              min="7"
              max="20"
              id="valueColor"
              class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
              @change="changeXaxisLabelSizeHandler($event)"
              :value="xaxisLabelSize"
            />
          </div>

          <div class="flex flex-col mt-3">
            <p class="label_heading">Y-axis label size</p>
            <input
              type="number"
              min="7"
              max="20"
              id="valueColor"
              class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
              @change="changeYaxisLabelSizeHandler($event)"
              :value="yaxisLabelSize"
            />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Grid Color</p>
            <input
              type="color"
              id="color1"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @change="changeGridColorHandler($event)"
              :value="gridColor"
            />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Background color</p>
            <input
              type="color"
              id="color1"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @change="changeGridBackgroundColor($event)"
              :value="gridBackgroundColor"
            />
          </div>
        </div>
      </div>

      <div
        v-on:click="toggle_height_handler_four"
        class="flex justify-between pt-4 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/menu_4.png" alt="" />
            Ranges option
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureFour"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureFour ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="">
          <div class="custom-radio-wrap mb-4">
            <div class="flex items-center justify-between mt-3 px-1">
              <p class="label_heading">X-Manual Scale</p>
              <input
                type="checkbox"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                @change="changeXManualhandler($event)"
                :checked="xManualRange"
              />
            </div>
          </div>
            <div class="custom-radio-wrap mb-4">
              <div class="flex flex-col mt-3">
                <p class="label_heading">X-Start Range</p>
                <input
                  type="number"
                  min="7"
                  max="20"
                  id="valueColor"
                  class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                  @change="changeXstartRange($event)"
                  :value="xStartRange"
                  :disabled="!xManualScale"
                />
              </div>
              <!-- <div class="flex items-center justify-between mt-3">
                <p class="label_heading">X-Start Range</p>
                <input
                  type="number"
                  class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
                  :class="darkDark ? 'input-bg' : 'input-bg_light'"
                  @change="changeXstartRange($event)"
                  :value="xStartRange"
                  :disabled="!xManualScale"
                />
              </div> -->
            </div>
            <div class="custom-radio-wrap mb-4">
              <div class="flex flex-col mt-3">
                <p class="label_heading">X-End Range</p>
                <input
                  type="number"
                  min="7"
                  max="20"
                  id="valueColor"
                  class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                  @change="changeXendRange($event)"
                  :value="xEndRange"
                  :disabled="!xManualScale"
                />
              </div>
            </div>
          <div class="custom-radio-wrap mb-4">
            <div class="flex items-center justify-between mt-3 px-1">
              <p class="label_heading">Y-Manual Scale</p>
              <input
                type="checkbox"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                @change="changeYManualhandler($event)"
                :checked="yManualRange"
              />
            </div>
          </div>
            <div class="custom-radio-wrap mb-4">
              <div class="flex flex-col mt-3">
                <p class="label_heading">Y-Start Range</p>
                <input
                  type="number"
                  min="7"
                  max="20"
                  id="valueColor"
                  class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                  @change="changeYstartRange($event)"
                  :value="yStartRange"
                  :disabled="!yManualScale"
                />
              </div>
            </div>
            <div class="custom-radio-wrap mb-4">
              <div class="flex flex-col mt-3">
                <p class="label_heading">Y-End Range</p>
                <input
                  type="number"
                  min="7"
                  max="20"
                  id="valueColor"
                  class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                  @change="changeYendRange($event)"
                  :value="yEndRange"
                  :disabled="!yManualScale"
                />
              </div>
            </div>
        </div>
      </div>

      <div
        v-on:click="isUnitOptionsExpand=!isUnitOptionsExpand"
        class="flex justify-between pt-4 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/menu_4.png" alt="" />
            Unit
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isUnitOptionsExpand"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isUnitOptionsExpand ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="">
          <div class="custom-radio-wrap mb-4">
           
             <UnitSelect :display-id="displayId" :widget-id="widgetId" :mnemonic="'tvd'" />
          </div>
            
        </div>
      </div>

      <UpDownButtons
        :isExpandFeatureSix="isExpandFeatureSix"
        :toggle_height_handler_six="toggle_height_handler_six"
        :id="id"
        :displayId="properties.displayId"
        :widgetId="properties.widgetId"
        :closePropertyFile="closeNavigation"
      />
    </div>
  </nav>
</template>

<script>
import UpDownButtons from "../UpDownButtons/UpDownButtons";
import UnitSelect from "./properties/UnitSelect.vue";

export default {
  name: "Maindash",
  components: {
    UpDownButtons,
    UnitSelect
  },
  props: {
    widgetId: String,
    displayId: String,
    toggle_right_Sidebar: {
      type: String,
      default: "",
    },
    toggle_right_margin: {
      type: String,
      default: "",
    },
    id: "",
    properties: {},
    closeNavigation: Function,
  },
  data() {
    return {
      idx: -1,
      isExpandFeatureTwo: true,
      isExpandFeatureThree: false,
      isExpandFeatureFour: false,
      isExpandFeatureFive: false,
      isExpandFeatureSix: false,
      xManualScale: false,
      yManualScale: false,
      isUnitOptionsExpand:false
    };
  },

  beforeMount() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.idx = i;
      }
    }
  },

  computed: {
    graphLabelColor() {
      if(Object.keys(this.$store.state.rect.rects[this.idx]).includes('graphLabelColor')){
        return this.$store.state.rect.rects[this.idx].graphLabelColor;
      }
      else{
        return getComputedStyle(document.documentElement).getPropertyValue("--textColor");
      }
    },
    graphColor() {
      if(Object.keys(this.$store.state.rect.rects[this.idx]).includes('graphColor')){
        return this.$store.state.rect.rects[this.idx].graphColor;
      }
      else{
        return getComputedStyle(document.documentElement).getPropertyValue("--textColor");
      }
    },
    xaxisLabelSize() {
      return this.$store.state.rect.rects[this.idx].xaxisLabelSize;
    },
    yaxisLabelSize() {
      return this.$store.state.rect.rects[this.idx].yaxisLabelSize;
    },
    gridColor() {
      if(Object.keys(this.$store.state.rect.rects[this.idx]).includes('gridColor')){
        return this.$store.state.rect.rects[this.idx].gridColor;
      }
      else{
        return getComputedStyle(document.documentElement).getPropertyValue("--textColor");
      }
    },
    gridBackgroundColor() {
      if(Object.keys(this.$store.state.rect.rects[this.idx]).includes('gridBackgroundColor')){
        return this.$store.state.rect.rects[this.idx].gridBackgroundColor;
      }
      else{
        return getComputedStyle(document.documentElement).getPropertyValue("--navBar2Bg");
      }
    },
    xManualRange() {
      return this.$store.state.rect.rects[this.idx].xManualRange;
    },
    xStartRange() {
      return this.$store.state.rect.rects[this.idx].xStartRange;
    },
    xEndRange() {
      return this.$store.state.rect.rects[this.idx].xEndRange;
    },
    yManualRange() {
      return this.$store.state.rect.rects[this.idx].yManualRange;
    },
    yStartRange() {
      return this.$store.state.rect.rects[this.idx].yStartRange;
    },
    yEndRange() {
      return this.$store.state.rect.rects[this.idx].yEndRange;
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },

  methods: {
    dark() {
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      this.darkMode = false;
      this.$emit("light");
    },
    changeGraphLabelColorHandler(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changeGraphLabelColor", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: e.target.value,
        });
      }, 300);
    },
    changeGraphColorHandler(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/swabSurgeRealTimedata", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: e.target.value,
          field: 'graphColor',
        });
      }, 300);
    },
    changeXaxisLabelSizeHandler(e) {
      if (Number(e.target.value) > 6 && 16 >= Number(e.target.value)) {
        this.$store.dispatch("rect/changeAxisLabelSize", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: +e.target.value,
        });
      } else {
        this.$toast.error(`Fonts should be from 7 to 16`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    changeYaxisLabelSizeHandler(e) {
      if (Number(e.target.value) > 6 && 16 >= Number(e.target.value)) {
        this.$store.dispatch("rect/changeYxisLabelSize", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: +e.target.value,
        });
      } else {
        this.$toast.error(`Fonts should be from 7 to 16`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },

    changeGridColorHandler(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changeGridColor", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: e.target.value,
        });
      }, 300);
    },
    changeGridBackgroundColor(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changeGraphBackground", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: e.target.value,
        });
      }, 300);
    },
    changeXManualhandler(e) {
      this.xManualScale = !this.xManualScale;
      setTimeout(() => {
        this.$store.dispatch("rect/changeXmanualRange", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: e.target.checked,
        });
      }, 300);
    },
    changeXstartRange(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changeXstartRange", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: +e.target.value,
        });
      }, 300);
    },
    changeXendRange(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changeXEndRange", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: +e.target.value,
        });
      }, 300);
    },
    changeYManualhandler(e) {
      this.yManualScale = !this.yManualScale;
      setTimeout(() => {
        this.$store.dispatch("rect/changeYmanualRange", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: e.target.checked,
        });
      }, 300);
    },
    changeYstartRange(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changeYstartRange", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: +e.target.value,
        });
      }, 300);
    },
    changeYendRange(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changeYEndRange", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: +e.target.value,
        });
      }, 300);
    },

    toggle_height_handler_one() {
      if (this.isExpandFeatureOne == true) {
        this.isExpandFeatureOne = false;
      } else {
        this.isExpandFeatureOne = true;
      }
    },
    toggle_height_handler_two() {
      if (this.isExpandFeatureTwo == true) {
        this.isExpandFeatureTwo = false;
      } else {
        this.isExpandFeatureTwo = true;
      }
    },
    toggle_height_handler_three() {
      if (this.isExpandFeatureThree == true) {
        this.isExpandFeatureThree = false;
      } else {
        this.isExpandFeatureThree = true;
      }
    },
    toggle_height_handler_four() {
      if (this.isExpandFeatureFour == true) {
        this.isExpandFeatureFour = false;
      } else {
        this.isExpandFeatureFour = true;
      }
    },
    toggle_height_handler_five() {
      if (this.isExpandFeatureFive == true) {
        this.isExpandFeatureFive = false;
      } else {
        this.isExpandFeatureFive = true;
      }
    },
    toggle_height_handler_six() {
      if (this.isExpandFeatureSix == true) {
        this.isExpandFeatureSix = false;
      } else {
        this.isExpandFeatureSix = true;
      }
    },
  },
};
</script>

<style scoped src="../../assets/styles/propstyle.css">
/* .right_bg {
  background: #1b1a1f;
}
.bg_gray {
  width: 12%;
}
.bg-right_ {
  background: #28282f;
  margin: 5px 5px;
  padding: 5px;
  border-radius: 5px;
}
.sidebar-heading {
  font-size: 9px;
  padding: 2px;
}
.sidebar-label {
  font-size: 10px;
  font-family: 'Poppins', sans-serif;
}
.label_heading {
  color: white !important;
  font-size: 8px;
  letter-spacing: 0.5px;
}
.custom-radio-wrap form .form-group-radio .label-text {
  color: white;
}

.font-color {
  color: white;
  font-size: 10px;
  padding: 0px 0px;
  height: 20px;
  width: 53%;
  text-align: center;
} */
</style>
